import { ApolloProvider } from "@apollo/client";
import { NextPage } from "next";
import { AppProps } from "next/app";
import Head from "next/head";
import { ReactElement, ReactNode } from "react";

import { FontsLoader } from "../components/FontLoader";

import { GAScript } from "src/analytics/ga";
import { useApollo } from "src/hooks/useApollo";
import { CustomerChakraProvider } from "src/providers/CustomerChakraProvider";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const client = useApollo(pageProps.initialApolloState);

  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <title>Speria</title>
        <meta name="robots" content="noindex" />
      </Head>

      <GAScript />

      <ApolloProvider client={client}>
        <CustomerChakraProvider>
          <FontsLoader>{getLayout(<Component {...pageProps} />)}</FontsLoader>
        </CustomerChakraProvider>
      </ApolloProvider>
    </>
  );
};

export default App;
