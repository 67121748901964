/* eslint-disable */
import * as types from "./graphql";
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "fragment ShopCardUnit on ShopCard {\n  id\n  code\n  shop {\n    id\n    name\n    postcode\n    prefecture\n    city\n    address\n    shopCardImgUrl\n  }\n}\n\nfragment CustomerUnit on Customer {\n  id\n  profile {\n    id\n    firstNameKana\n    lastNameKana\n    gender\n    phone\n    birthYear\n  }\n  memberships {\n    id\n    membershipVisits {\n      id\n      visitedAt\n    }\n  }\n}":
    types.ShopCardUnitFragmentDoc,
  "query FetchViewer {\n  viewer {\n    id\n    profile {\n      firstNameKana\n      lastNameKana\n      gender\n      phone\n      birthYear\n    }\n    memberships {\n      id\n      membershipVisits {\n        id\n        visitedAt\n      }\n    }\n  }\n}":
    types.FetchViewerDocument,
  "mutation Checkin_DoCheckin($shopCardId: String!) {\n  checkin(input: {shopCardId: $shopCardId}) {\n    id\n  }\n}":
    types.Checkin_DoCheckinDocument,
  "query FetchShopData($shopId: ID!) {\n  shop(id: $shopId) {\n    id\n    name\n    postcode\n    prefecture\n    city\n    address\n    shopCardImgUrl\n  }\n}\n\nquery FetchShopCardData($shopCardId: ID!) {\n  shopCard(id: $shopCardId) {\n    ...ShopCardUnit\n  }\n}\n\nmutation DestroyAuthSession {\n  destroyAuthSession(input: {})\n}":
    types.FetchShopDataDocument,
  "query FetchMembership($id: ID!) {\n  membership(id: $id) {\n    id\n    visits(first: 3) {\n      totalCount\n      edges {\n        cursor\n        node {\n          id\n          createdAt\n          visitorCount\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n}":
    types.FetchMembershipDocument,
  "query FetchVisit($id: ID!) {\n  visit(id: $id) {\n    id\n    visitorCount\n    createdAt\n    shop {\n      id\n      name\n    }\n    orders {\n      id\n      createdAt\n      orderItems {\n        id\n        name\n        subCategory\n        description\n        courseMenuDetail {\n          foods {\n            name\n            description\n          }\n          drinks {\n            name\n            description\n          }\n        }\n      }\n    }\n  }\n}":
    types.FetchVisitDocument,
  "mutation LoginProfile_RegisterProfile($firstNameKana: String!, $lastNameKana: String!, $gender: Gender!, $phone: String!, $birthYear: Int!) {\n  registerProfile(\n    input: {firstNameKana: $firstNameKana, lastNameKana: $lastNameKana, gender: $gender, phone: $phone, birthYear: $birthYear}\n  ) {\n    id\n    profile {\n      id\n    }\n  }\n}":
    types.LoginProfile_RegisterProfileDocument,
  "mutation LoginVerificationCode_VerifyFirebaseIdToken($firebaseIdToken: String!, $shopCardId: String!) {\n  verifyFirebaseIdToken(\n    input: {firebaseIdToken: $firebaseIdToken, shopCardId: $shopCardId}\n  ) {\n    id\n    profile {\n      id\n    }\n  }\n}":
    types.LoginVerificationCode_VerifyFirebaseIdTokenDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "fragment ShopCardUnit on ShopCard {\n  id\n  code\n  shop {\n    id\n    name\n    postcode\n    prefecture\n    city\n    address\n    shopCardImgUrl\n  }\n}\n\nfragment CustomerUnit on Customer {\n  id\n  profile {\n    id\n    firstNameKana\n    lastNameKana\n    gender\n    phone\n    birthYear\n  }\n  memberships {\n    id\n    membershipVisits {\n      id\n      visitedAt\n    }\n  }\n}",
): (typeof documents)["fragment ShopCardUnit on ShopCard {\n  id\n  code\n  shop {\n    id\n    name\n    postcode\n    prefecture\n    city\n    address\n    shopCardImgUrl\n  }\n}\n\nfragment CustomerUnit on Customer {\n  id\n  profile {\n    id\n    firstNameKana\n    lastNameKana\n    gender\n    phone\n    birthYear\n  }\n  memberships {\n    id\n    membershipVisits {\n      id\n      visitedAt\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchViewer {\n  viewer {\n    id\n    profile {\n      firstNameKana\n      lastNameKana\n      gender\n      phone\n      birthYear\n    }\n    memberships {\n      id\n      membershipVisits {\n        id\n        visitedAt\n      }\n    }\n  }\n}",
): (typeof documents)["query FetchViewer {\n  viewer {\n    id\n    profile {\n      firstNameKana\n      lastNameKana\n      gender\n      phone\n      birthYear\n    }\n    memberships {\n      id\n      membershipVisits {\n        id\n        visitedAt\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation Checkin_DoCheckin($shopCardId: String!) {\n  checkin(input: {shopCardId: $shopCardId}) {\n    id\n  }\n}",
): (typeof documents)["mutation Checkin_DoCheckin($shopCardId: String!) {\n  checkin(input: {shopCardId: $shopCardId}) {\n    id\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchShopData($shopId: ID!) {\n  shop(id: $shopId) {\n    id\n    name\n    postcode\n    prefecture\n    city\n    address\n    shopCardImgUrl\n  }\n}\n\nquery FetchShopCardData($shopCardId: ID!) {\n  shopCard(id: $shopCardId) {\n    ...ShopCardUnit\n  }\n}\n\nmutation DestroyAuthSession {\n  destroyAuthSession(input: {})\n}",
): (typeof documents)["query FetchShopData($shopId: ID!) {\n  shop(id: $shopId) {\n    id\n    name\n    postcode\n    prefecture\n    city\n    address\n    shopCardImgUrl\n  }\n}\n\nquery FetchShopCardData($shopCardId: ID!) {\n  shopCard(id: $shopCardId) {\n    ...ShopCardUnit\n  }\n}\n\nmutation DestroyAuthSession {\n  destroyAuthSession(input: {})\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchMembership($id: ID!) {\n  membership(id: $id) {\n    id\n    visits(first: 3) {\n      totalCount\n      edges {\n        cursor\n        node {\n          id\n          createdAt\n          visitorCount\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n}",
): (typeof documents)["query FetchMembership($id: ID!) {\n  membership(id: $id) {\n    id\n    visits(first: 3) {\n      totalCount\n      edges {\n        cursor\n        node {\n          id\n          createdAt\n          visitorCount\n        }\n      }\n      pageInfo {\n        hasNextPage\n        endCursor\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "query FetchVisit($id: ID!) {\n  visit(id: $id) {\n    id\n    visitorCount\n    createdAt\n    shop {\n      id\n      name\n    }\n    orders {\n      id\n      createdAt\n      orderItems {\n        id\n        name\n        subCategory\n        description\n        courseMenuDetail {\n          foods {\n            name\n            description\n          }\n          drinks {\n            name\n            description\n          }\n        }\n      }\n    }\n  }\n}",
): (typeof documents)["query FetchVisit($id: ID!) {\n  visit(id: $id) {\n    id\n    visitorCount\n    createdAt\n    shop {\n      id\n      name\n    }\n    orders {\n      id\n      createdAt\n      orderItems {\n        id\n        name\n        subCategory\n        description\n        courseMenuDetail {\n          foods {\n            name\n            description\n          }\n          drinks {\n            name\n            description\n          }\n        }\n      }\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation LoginProfile_RegisterProfile($firstNameKana: String!, $lastNameKana: String!, $gender: Gender!, $phone: String!, $birthYear: Int!) {\n  registerProfile(\n    input: {firstNameKana: $firstNameKana, lastNameKana: $lastNameKana, gender: $gender, phone: $phone, birthYear: $birthYear}\n  ) {\n    id\n    profile {\n      id\n    }\n  }\n}",
): (typeof documents)["mutation LoginProfile_RegisterProfile($firstNameKana: String!, $lastNameKana: String!, $gender: Gender!, $phone: String!, $birthYear: Int!) {\n  registerProfile(\n    input: {firstNameKana: $firstNameKana, lastNameKana: $lastNameKana, gender: $gender, phone: $phone, birthYear: $birthYear}\n  ) {\n    id\n    profile {\n      id\n    }\n  }\n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "mutation LoginVerificationCode_VerifyFirebaseIdToken($firebaseIdToken: String!, $shopCardId: String!) {\n  verifyFirebaseIdToken(\n    input: {firebaseIdToken: $firebaseIdToken, shopCardId: $shopCardId}\n  ) {\n    id\n    profile {\n      id\n    }\n  }\n}",
): (typeof documents)["mutation LoginVerificationCode_VerifyFirebaseIdToken($firebaseIdToken: String!, $shopCardId: String!) {\n  verifyFirebaseIdToken(\n    input: {firebaseIdToken: $firebaseIdToken, shopCardId: $shopCardId}\n  ) {\n    id\n    profile {\n      id\n    }\n  }\n}"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
