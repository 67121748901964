/* eslint-disable */
import type { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** An ISO 8601-encoded date */
  ISO8601Date: { input: string; output: string };
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string };
};

export type Catalog = {
  __typename?: "Catalog";
  categories: Array<CatalogCategory>;
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  ordinal: Scalars["Int"]["output"];
  variations: Array<CatalogVariation>;
};

export type CatalogCategory = "Beverage" | "Course" | "Food";

export type CatalogSubCategory =
  | "Appetizer"
  | "BottleWineRed"
  | "BottleWineRose"
  | "BottleWineSparkling"
  | "BottleWineWhite"
  | "Course"
  | "CourseOption"
  | "Dessert"
  | "DimSum"
  | "GlassWine"
  | "Handy"
  | "LunchMenu"
  | "LunchSideMenu"
  | "Main"
  | "NonAlcoholic"
  | "NoodleRice"
  | "OtherDrink"
  | "Tea";

export type CatalogVariation = {
  __typename?: "CatalogVariation";
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  ordinal: Scalars["Int"]["output"];
  price: Scalars["Int"]["output"];
};

/** Autogenerated input type of Checkin */
export type CheckinInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  shopCardId: Scalars["String"]["input"];
};

export type CourseMenuDetail = {
  __typename?: "CourseMenuDetail";
  drinks: Array<CourseMenuDetailDetailType>;
  foods: Array<CourseMenuDetailDetailType>;
};

export type CourseMenuDetailDetailType = {
  __typename?: "CourseMenuDetailDetailType";
  description: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export type Customer = {
  __typename?: "Customer";
  id: Scalars["ID"]["output"];
  membershipByShopCardId: Membership;
  memberships: Array<Membership>;
  profile: Maybe<Profile>;
};

export type CustomerMembershipByShopCardIdArgs = {
  shopCardId: Scalars["ID"]["input"];
};

/** Autogenerated input type of DestroyAuthSession */
export type DestroyAuthSessionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

export type Gender = "Female" | "Male" | "NotApplicable" | "Unknown";

export type Membership = {
  __typename?: "Membership";
  customer: Customer;
  id: Scalars["ID"]["output"];
  membershipVisits: Array<MembershipVisit>;
  shop: Shop;
  visits: VisitConnection;
};

export type MembershipVisitsArgs = {
  after: InputMaybe<Scalars["String"]["input"]>;
  before: InputMaybe<Scalars["String"]["input"]>;
  first: InputMaybe<Scalars["Int"]["input"]>;
  last: InputMaybe<Scalars["Int"]["input"]>;
};

export type MembershipVisit = {
  __typename?: "MembershipVisit";
  id: Scalars["ID"]["output"];
  membership: Membership;
  visit: Visit;
  visitedAt: Scalars["ISO8601Date"]["output"];
};

export type Mutation = {
  __typename?: "Mutation";
  checkin: Membership;
  destroyAuthSession: Scalars["Boolean"]["output"];
  registerProfile: Customer;
  verifyFirebaseIdToken: Customer;
};

export type MutationCheckinArgs = {
  input: CheckinInput;
};

export type MutationDestroyAuthSessionArgs = {
  input: DestroyAuthSessionInput;
};

export type MutationRegisterProfileArgs = {
  input: RegisterProfileInput;
};

export type MutationVerifyFirebaseIdTokenArgs = {
  input: VerifyFirebaseIdTokenInput;
};

export type Order = {
  __typename?: "Order";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  orderItems: Array<OrderItem>;
};

export type OrderItem = {
  __typename?: "OrderItem";
  amount: Scalars["Int"]["output"];
  courseMenuDetail: Maybe<CourseMenuDetail>;
  description: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  price: Scalars["Int"]["output"];
  quantity: Scalars["Int"]["output"];
  subCategory: CatalogSubCategory;
  variation: Maybe<Scalars["String"]["output"]>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor: Maybe<Scalars["String"]["output"]>;
};

export type Profile = {
  __typename?: "Profile";
  birthYear: Scalars["Int"]["output"];
  firstNameKana: Scalars["String"]["output"];
  gender: Gender;
  id: Scalars["ID"]["output"];
  lastNameKana: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
};

export type Query = {
  __typename?: "Query";
  /** Fetches a list of catalogs. */
  catalogs: Array<Catalog>;
  membership: Membership;
  shop: Shop;
  shopCard: ShopCard;
  viewer: Customer;
  visit: Visit;
};

export type QueryMembershipArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryShopArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryShopCardArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryVisitArgs = {
  id: Scalars["ID"]["input"];
};

/** Autogenerated input type of RegisterProfile */
export type RegisterProfileInput = {
  birthYear: Scalars["Int"]["input"];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  firstNameKana: Scalars["String"]["input"];
  gender: Gender;
  lastNameKana: Scalars["String"]["input"];
  phone: Scalars["String"]["input"];
};

export type Seat = {
  __typename?: "Seat";
  id: Scalars["ID"]["output"];
};

export type Shop = {
  __typename?: "Shop";
  address: Scalars["String"]["output"];
  city: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  phone: Scalars["String"]["output"];
  postcode: Scalars["String"]["output"];
  prefecture: Scalars["String"]["output"];
  shopCardImgUrl: Maybe<Scalars["String"]["output"]>;
};

export type ShopCard = {
  __typename?: "ShopCard";
  code: Maybe<Scalars["String"]["output"]>;
  hasMembership: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  membership: Membership;
  shop: Shop;
};

/** Autogenerated input type of VerifyFirebaseIdToken */
export type VerifyFirebaseIdTokenInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  firebaseIdToken: Scalars["String"]["input"];
  shopCardId: Scalars["String"]["input"];
};

export type Visit = {
  __typename?: "Visit";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  menuType: Scalars["String"]["output"];
  orders: Array<Order>;
  seats: Array<Seat>;
  shop: Shop;
  visitedAt: Scalars["ISO8601Date"]["output"];
  visitorCount: Scalars["Int"]["output"];
};

/** The connection type for Visit. */
export type VisitConnection = {
  __typename?: "VisitConnection";
  /** A list of edges. */
  edges: Array<VisitEdge>;
  /** A list of nodes. */
  nodes: Array<Visit>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An edge in a connection. */
export type VisitEdge = {
  __typename?: "VisitEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Visit;
};

export type ShopCardUnitFragment = {
  __typename?: "ShopCard";
  id: string;
  code: string | null;
  shop: {
    __typename?: "Shop";
    id: string;
    name: string;
    postcode: string;
    prefecture: string;
    city: string;
    address: string;
    shopCardImgUrl: string | null;
  };
} & { " $fragmentName"?: "ShopCardUnitFragment" };

export type CustomerUnitFragment = {
  __typename?: "Customer";
  id: string;
  profile: {
    __typename?: "Profile";
    id: string;
    firstNameKana: string;
    lastNameKana: string;
    gender: Gender;
    phone: string;
    birthYear: number;
  } | null;
  memberships: Array<{
    __typename?: "Membership";
    id: string;
    membershipVisits: Array<{
      __typename?: "MembershipVisit";
      id: string;
      visitedAt: string;
    }>;
  }>;
} & { " $fragmentName"?: "CustomerUnitFragment" };

export type FetchViewerQueryVariables = Exact<{ [key: string]: never }>;

export type FetchViewerQuery = {
  __typename?: "Query";
  viewer: {
    __typename?: "Customer";
    id: string;
    profile: {
      __typename?: "Profile";
      firstNameKana: string;
      lastNameKana: string;
      gender: Gender;
      phone: string;
      birthYear: number;
    } | null;
    memberships: Array<{
      __typename?: "Membership";
      id: string;
      membershipVisits: Array<{
        __typename?: "MembershipVisit";
        id: string;
        visitedAt: string;
      }>;
    }>;
  };
};

export type Checkin_DoCheckinMutationVariables = Exact<{
  shopCardId: Scalars["String"]["input"];
}>;

export type Checkin_DoCheckinMutation = {
  __typename?: "Mutation";
  checkin: { __typename?: "Membership"; id: string };
};

export type FetchShopDataQueryVariables = Exact<{
  shopId: Scalars["ID"]["input"];
}>;

export type FetchShopDataQuery = {
  __typename?: "Query";
  shop: {
    __typename?: "Shop";
    id: string;
    name: string;
    postcode: string;
    prefecture: string;
    city: string;
    address: string;
    shopCardImgUrl: string | null;
  };
};

export type FetchShopCardDataQueryVariables = Exact<{
  shopCardId: Scalars["ID"]["input"];
}>;

export type FetchShopCardDataQuery = {
  __typename?: "Query";
  shopCard: { __typename?: "ShopCard" } & {
    " $fragmentRefs"?: { ShopCardUnitFragment: ShopCardUnitFragment };
  };
};

export type DestroyAuthSessionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type DestroyAuthSessionMutation = {
  __typename?: "Mutation";
  destroyAuthSession: boolean;
};

export type FetchMembershipQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchMembershipQuery = {
  __typename?: "Query";
  membership: {
    __typename?: "Membership";
    id: string;
    visits: {
      __typename?: "VisitConnection";
      totalCount: number;
      edges: Array<{
        __typename?: "VisitEdge";
        cursor: string;
        node: {
          __typename?: "Visit";
          id: string;
          createdAt: string;
          visitorCount: number;
        };
      }>;
      pageInfo: {
        __typename?: "PageInfo";
        hasNextPage: boolean;
        endCursor: string | null;
      };
    };
  };
};

export type FetchVisitQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type FetchVisitQuery = {
  __typename?: "Query";
  visit: {
    __typename?: "Visit";
    id: string;
    visitorCount: number;
    createdAt: string;
    shop: { __typename?: "Shop"; id: string; name: string };
    orders: Array<{
      __typename?: "Order";
      id: string;
      createdAt: string;
      orderItems: Array<{
        __typename?: "OrderItem";
        id: string;
        name: string;
        subCategory: CatalogSubCategory;
        description: string | null;
        courseMenuDetail: {
          __typename?: "CourseMenuDetail";
          foods: Array<{
            __typename?: "CourseMenuDetailDetailType";
            name: string;
            description: string | null;
          }>;
          drinks: Array<{
            __typename?: "CourseMenuDetailDetailType";
            name: string;
            description: string | null;
          }>;
        } | null;
      }>;
    }>;
  };
};

export type LoginProfile_RegisterProfileMutationVariables = Exact<{
  firstNameKana: Scalars["String"]["input"];
  lastNameKana: Scalars["String"]["input"];
  gender: Gender;
  phone: Scalars["String"]["input"];
  birthYear: Scalars["Int"]["input"];
}>;

export type LoginProfile_RegisterProfileMutation = {
  __typename?: "Mutation";
  registerProfile: {
    __typename?: "Customer";
    id: string;
    profile: { __typename?: "Profile"; id: string } | null;
  };
};

export type LoginVerificationCode_VerifyFirebaseIdTokenMutationVariables =
  Exact<{
    firebaseIdToken: Scalars["String"]["input"];
    shopCardId: Scalars["String"]["input"];
  }>;

export type LoginVerificationCode_VerifyFirebaseIdTokenMutation = {
  __typename?: "Mutation";
  verifyFirebaseIdToken: {
    __typename?: "Customer";
    id: string;
    profile: { __typename?: "Profile"; id: string } | null;
  };
};

export const ShopCardUnitFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopCardUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopCard" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "postcode" } },
                { kind: "Field", name: { kind: "Name", value: "prefecture" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shopCardImgUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ShopCardUnitFragment, unknown>;
export const CustomerUnitFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CustomerUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Customer" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "profile" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "firstNameKana" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastNameKana" },
                },
                { kind: "Field", name: { kind: "Name", value: "gender" } },
                { kind: "Field", name: { kind: "Name", value: "phone" } },
                { kind: "Field", name: { kind: "Name", value: "birthYear" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "memberships" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "membershipVisits" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "visitedAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<CustomerUnitFragment, unknown>;
export const FetchViewerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchViewer" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "viewer" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "firstNameKana" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastNameKana" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "gender" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "phone" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "birthYear" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "memberships" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "membershipVisits" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "visitedAt" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchViewerQuery, FetchViewerQueryVariables>;
export const Checkin_DoCheckinDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "Checkin_DoCheckin" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopCardId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "checkin" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopCardId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopCardId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  Checkin_DoCheckinMutation,
  Checkin_DoCheckinMutationVariables
>;
export const FetchShopDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchShopData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "shopId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "postcode" } },
                { kind: "Field", name: { kind: "Name", value: "prefecture" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shopCardImgUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchShopDataQuery, FetchShopDataQueryVariables>;
export const FetchShopCardDataDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchShopCardData" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopCardId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "shopCard" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "shopCardId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ShopCardUnit" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ShopCardUnit" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ShopCard" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "code" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "shop" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "postcode" } },
                { kind: "Field", name: { kind: "Name", value: "prefecture" } },
                { kind: "Field", name: { kind: "Name", value: "city" } },
                { kind: "Field", name: { kind: "Name", value: "address" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shopCardImgUrl" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchShopCardDataQuery,
  FetchShopCardDataQueryVariables
>;
export const DestroyAuthSessionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "DestroyAuthSession" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "destroyAuthSession" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "ObjectValue", fields: [] },
              },
            ],
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  DestroyAuthSessionMutation,
  DestroyAuthSessionMutationVariables
>;
export const FetchMembershipDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchMembership" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "membership" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visits" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "first" },
                      value: { kind: "IntValue", value: "3" },
                    },
                  ],
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "totalCount" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "edges" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "cursor" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "node" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "visitorCount",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "pageInfo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "hasNextPage" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "endCursor" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  FetchMembershipQuery,
  FetchMembershipQueryVariables
>;
export const FetchVisitDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "FetchVisit" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "visit" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "visitorCount" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "shop" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "orders" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "orderItems" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "subCategory" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "courseMenuDetail" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "foods" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "drinks" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchVisitQuery, FetchVisitQueryVariables>;
export const LoginProfile_RegisterProfileDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "LoginProfile_RegisterProfile" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firstNameKana" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "lastNameKana" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "gender" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Gender" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "phone" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "birthYear" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "registerProfile" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "firstNameKana" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "firstNameKana" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "lastNameKana" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "lastNameKana" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "gender" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "gender" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "phone" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "phone" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "birthYear" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "birthYear" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginProfile_RegisterProfileMutation,
  LoginProfile_RegisterProfileMutationVariables
>;
export const LoginVerificationCode_VerifyFirebaseIdTokenDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: {
        kind: "Name",
        value: "LoginVerificationCode_VerifyFirebaseIdToken",
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "firebaseIdToken" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "shopCardId" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "verifyFirebaseIdToken" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "ObjectValue",
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "firebaseIdToken" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "firebaseIdToken" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "shopCardId" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "shopCardId" },
                      },
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "profile" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  LoginVerificationCode_VerifyFirebaseIdTokenMutation,
  LoginVerificationCode_VerifyFirebaseIdTokenMutationVariables
>;
